import React from 'react';

export function FacebookIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.1104 12.0331C21.1104 7.04428 17.0562 3 12.0552 3C7.0542 3 3 7.04428 3 12.0331C3 16.2692 5.92375 19.8239 9.86784 20.8002V14.7936H8.00065V12.0331H9.86784V10.8436C9.86784 7.76909 11.2627 6.34404 14.2886 6.34404C14.8623 6.34404 15.8522 6.45641 16.2572 6.56842V9.07058C16.0435 9.04818 15.6722 9.03697 15.2111 9.03697C13.7264 9.03697 13.1527 9.59811 13.1527 11.0568V12.0331H16.1105L15.6023 14.7936H13.1527V21C17.6358 20.4598 21.1104 16.6515 21.1104 12.0331Z"
        fill="#0966FF"
      />
    </svg>
  );
}
