import { string as zString } from 'zod';
import { postAppleLogin } from '@hungryroot/rest/api/apple_login/post';
import { StatusCode } from '@hungryroot/rest';
import { loadScript } from './provider';
import { logSso } from './o11y';

export type AppleSdk = AppleSignInAPI.AppleID;

export const SCRIPT_SRC =
  'https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js';

const APPLE_CLIENT_ID = zString().parse(process.env.PUBLIC_APPLE_CLIENT_ID);
const APPLE_REDIRECT_URI = zString().parse(
  process.env.PUBLIC_APPLE_REDIRECT_URI
);

type Params = {
  onReady: (sdk: AppleSignInAPI.AppleID) => void;
};

export async function loadAppleSdk({ onReady }: Params) {
  if (window.AppleID !== undefined) {
    onReady(window.AppleID);
    return;
  }

  const sdk = await loadScript({ src: SCRIPT_SRC, globalProperty: 'AppleID' });

  sdk.auth.init({
    clientId: APPLE_CLIENT_ID,
    redirectURI: APPLE_REDIRECT_URI,
    state: '{{ APPLE_STATE }}',
    scope: 'name email',
    usePopup: true,
  });

  onReady(sdk);
}

type AuthParams = {
  dataLayer: unknown[];
  ga: UniversalAnalytics.ga;
  appleId: {
    auth: Pick<AppleSignInAPI.AppleID['auth'], 'signIn'>;
  };
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: (e: unknown) => void;
};

export function appleAuth({
  onError,
  onLoginSuccess,
  ga,
  dataLayer,
  appleId,
  onSignupSuccess,
}: AuthParams) {
  logSso({
    lifecycle: 'initiated',
    provider: 'apple',
  });
  appleId.auth
    .signIn()
    .then(async ({ authorization: { code, id_token, state } }) => {
      const resp = await postAppleLogin({
        code,
        idToken: id_token,
        isWeb: true,
        state,
      });

      if (resp.status !== StatusCode.SuccessOK) {
        throw {
          message: 'api_apple_login_failed',
          statusCode: resp.status,
        };
      }

      const {
        data: {
          created,
          customer: { id: customerId, email: customerEmail },
        },
      } = resp;
      const eventType = created === true ? 'signup' : 'login';
      logSso({
        lifecycle: 'success',
        provider: 'apple',
        eventType,
      });

      dataLayer.push({
        event: `hr.${eventType}.success`,
        [eventType]: {
          method: 'apple',
        },
        customer: {
          id: customerId,
          email: customerEmail,
        },
      });

      if (created === true) {
        onSignupSuccess();
        return;
      }
      onLoginSuccess();
    })
    .catch((e) => {
      logSso({
        lifecycle: 'error',
        provider: 'apple',
        error: e,
      });
      ga('send', 'event', 'login', 'failure');
      onError(e);
    });
}
