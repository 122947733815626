import { logSso } from './o11y';

type SsoPropKeys = keyof Pick<typeof window, 'google' | 'AppleID' | 'FB'>;
type ScriptLoadProps<K extends SsoPropKeys> = {
  src: string;
  globalProperty: K;
};

type QueueCallback<T extends SsoPropKeys> = (value: (typeof window)[T]) => void;

const queue: Record<
  string,
  Array<
    QueueCallback<'google'> | QueueCallback<'AppleID'> | QueueCallback<'FB'>
  >
> = {};

export async function loadScript<
  K extends SsoPropKeys,
  V extends (typeof window)[K],
>(params: ScriptLoadProps<K>): Promise<V> {
  const { src, globalProperty } = params;
  return new Promise((res) => {
    let queued = queue[src] as Array<QueueCallback<K>>;
    if (queued === undefined) {
      queued = queue[src] = [];
    }
    queued.push(res as QueueCallback<K>);

    if (document.getElementById(src) !== null) {
      return;
    }

    const script = document.createElement('script');
    script.src = src;
    script.id = src;
    script.setAttribute('data-cy', src);
    script.addEventListener(
      'load',
      () => {
        const value = window[globalProperty];
        (queue[src] as Array<QueueCallback<K>>).forEach((queueRes) => {
          queueRes(value);
        });
        queue[src] = [];
      },
      { once: true }
    );

    script.addEventListener(
      'error',
      () => {
        logSso({
          lifecycle: 'load-error',
          error: {
            src: src,
            globalProperty,
          },
        });
      },
      { once: true }
    );
    document.body.appendChild(script);
  });
}
