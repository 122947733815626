import { AUTHORIZATION_HEADER } from '../../../src/authorization';
import { send } from '../../../src/network';

type Params = {
  userId: string;
  token: string;
};

type FacebookLoginResponse = {
  created: boolean;
  customer: {
    id: number;
    email: string;
  };
};

export function postFacebookLogin({ userId, token }: Params) {
  return send<FacebookLoginResponse>({
    path: '/api/facebook_login/',
    method: 'POST',
    body: {
      user_id: userId,
      token,
    },
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      AUTHORIZATION: AUTHORIZATION_HEADER,
    }),
  });
}
