import { log } from '@hungryroot/o11y';

type Params =
  | ({
      provider: 'apple' | 'facebook' | 'google';
    } & (
      | {
          lifecycle: 'initiated';
        }
      | {
          lifecycle: 'success';
          eventType: 'login' | 'signup';
        }
      | {
          lifecycle: 'error';
          error: unknown;
        }
      | {
          lifecycle: 'google_button';
          provider: 'google';
          buttonState: 'rendered' | 'clicked' | 'not_found';
        }
    ))
  | {
      lifecycle: 'load-error';
      error: {
        src: string;
        globalProperty: string;
      };
    };

export function logSso(params: Params) {
  const { lifecycle } = params;
  switch (lifecycle) {
    case 'error': {
      log('sso', {
        lifecycle,
        provider: params.provider,
        error: params.error,
      });
      return;
    }
    case 'success': {
      log('sso', {
        lifecycle,
        provider: params.provider,
        eventType: params.eventType,
      });
      return;
    }
    case 'load-error': {
      log('sso', {
        lifecycle,
        error: params.error,
      });
      return;
    }
    case 'google_button': {
      log('sso', {
        lifecycle,
        provider: params.provider,
        buttonState: params.buttonState,
      });
      return;
    }
    default: {
      log('sso', {
        lifecycle,
        provider: params.provider,
      });
    }
  }
}
