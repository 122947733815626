import { string as zString } from 'zod';
import { postGoogleLogin } from '@hungryroot/rest/api/google_login/post';
import StatusCode from 'status-code-enum';
import { loadScript } from './provider';
import { logSso } from './o11y';

export type Google = typeof google;
type CredentialResponse = google.accounts.id.CredentialResponse;

const GOOGLE_APP_ID = zString().parse(process.env.PUBLIC_GOOGLE_CLIENT_KEY);
const SCRIPT_SRC = 'https://accounts.google.com/gsi/client';

type LoadParams = {
  onReady(sdk: Google): void;
};

export async function loadGoogleSdk({ onReady }: LoadParams) {
  if (window.google !== undefined) {
    onReady(window.google);
    return;
  }

  const googleSdk = await loadScript({
    src: SCRIPT_SRC,
    globalProperty: 'google',
  });

  onReady(googleSdk);
}

type InitializeParams = {
  onAuthRequest: (credentials: Pick<CredentialResponse, 'credential'>) => void;
};

export function googleInitialize(
  googleSdk: {
    accounts: {
      id: Pick<Google['accounts']['id'], 'initialize' | 'renderButton'>;
    };
  },
  { onAuthRequest }: InitializeParams
) {
  googleSdk.accounts.id.initialize({
    client_id: GOOGLE_APP_ID,
    ux_mode: 'popup',
    callback: (response) => {
      onAuthRequest(response);
    },
  });
}

type AuthParams = {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: () => void;
  ga: UniversalAnalytics.ga;
  dataLayer: unknown[];
  credential: Pick<CredentialResponse, 'credential'>;
};

export async function googleAuth({
  onSignupSuccess,
  onLoginSuccess,
  onError,
  ga,
  dataLayer,
  credential,
}: AuthParams) {
  logSso({
    provider: 'google',
    lifecycle: 'initiated',
  });
  const resp = await postGoogleLogin({
    idToken: credential.credential,
  });

  if (resp.status !== StatusCode.SuccessOK) {
    logSso({
      provider: 'google',
      lifecycle: 'error',
      error: {
        message: 'api_google_login_failed',
        statusCode: 400,
      },
    });
    onError();
    return;
  }

  const {
    data: { customer, created },
  } = resp;
  const eventType = created === true ? 'signup' : 'login';

  ga('send', 'event', eventType, 'success');

  const customerData = {
    id: customer.id,
    email: customer.email,
  };

  dataLayer.push({
    event: `hr.${eventType}.success`,
    [eventType]: {
      method: 'google',
    },
    customer: customerData,
  });

  logSso({
    provider: 'google',
    lifecycle: 'success',
    eventType,
  });

  if (created === true) {
    onSignupSuccess();
    return;
  }

  onLoginSuccess();
}

export function createGoogleAuthButton(
  googleSdk: {
    accounts: {
      id: Pick<Google['accounts']['id'], 'renderButton'>;
    };
  },
  wrapper: HTMLElement
) {
  // Render the button inside our wrapper; button will not show
  googleSdk.accounts.id.renderButton(wrapper, {
    type: 'icon',
    width: 200,
  });

  logSso({
    provider: 'google',
    lifecycle: 'google_button',
    buttonState: 'rendered',
  });

  const googleLoginWrapperButton = wrapper.querySelector('div[role=button]');

  if (
    googleLoginWrapperButton === null ||
    !(googleLoginWrapperButton instanceof HTMLElement)
  ) {
    logSso({
      provider: 'google',
      lifecycle: 'google_button',
      buttonState: 'not_found',
    });
    return () => {};
  }

  return function () {
    logSso({
      provider: 'google',
      lifecycle: 'google_button',
      buttonState: 'clicked',
    });
    googleLoginWrapperButton.click();
  };
}
