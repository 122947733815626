import { AUTHORIZATION_HEADER } from '../../../src/authorization';
import { send } from '../../../src/network';

type Params = {
  code: string;
  idToken: string;
  isWeb: true;
  state: string;
};

type AppleLoginResponse = {
  created: boolean;
  customer: {
    // There are definitely more properties here
    // but we only ever need these from this endpoint
    id: number;
    email: string;
  };
};

export function postAppleLogin(params: Params) {
  return send<AppleLoginResponse>({
    path: '/api/apple_login/',
    method: 'POST',
    body: {
      code: params.code,
      id_token: params.idToken,
      state: params.state,
      is_web: params.isWeb,
    },
    headers: new Headers({
      'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
      AUTHORIZATION: AUTHORIZATION_HEADER,
    }),
  });
}
