import { AUTHORIZATION_HEADER } from '../../../src/authorization';
import { send } from '../../../src/network';
import { SignupResponse } from '../../signup/post';

type Params = {
  idToken: string;
};

type GoogleLoginResponse = {
  created: boolean;
  customer: SignupResponse;
};

export async function postGoogleLogin(params: Params) {
  return send<GoogleLoginResponse>({
    path: '/api/google_login/',
    method: 'POST',
    body: params,
    headers: new Headers({
      AUTHORIZATION: AUTHORIZATION_HEADER,
      'Content-Type': 'application/json',
    }),
  });
}
