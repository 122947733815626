import React, { useEffect, useState } from 'react';
import { AppleIcon } from '../../svg/AppleIcon';
import { AppleSdk, loadAppleSdk, appleAuth } from '../../provider/apple';
import { RoundedButton } from '../RoundedButton/RoundedButton';

type Props = {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: () => void;
  ga: UniversalAnalytics.ga;
  dataLayer: unknown[];
};

export function ButtonApple({
  ga,
  dataLayer,
  onLoginSuccess,
  onError,
  onSignupSuccess,
}: Props) {
  const [sdk, setSdk] = useState<AppleSdk | null>(null);
  useEffect(() => {
    loadAppleSdk({
      onReady: setSdk,
    });
  }, []);

  return (
    <RoundedButton
      disabled={sdk === null}
      onClick={() => {
        if (sdk === null) {
          return;
        }
        appleAuth({
          dataLayer,
          appleId: sdk,
          ga,
          onSignupSuccess,
          onLoginSuccess,
          onError,
        });
      }}
      aria-label="Sign up with Apple"
    >
      <AppleIcon />
    </RoundedButton>
  );
}
