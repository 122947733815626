import React, { ReactNode } from 'react';

import styles from './RoundedButton.module.css';

type Props = {
  disabled: boolean;
  onClick: () => void;
  children: ReactNode;
  'aria-label': string;
};

export function RoundedButton(props: Props) {
  const { children, disabled, onClick } = props;
  return (
    <button
      disabled={disabled}
      onClick={onClick}
      aria-label={props['aria-label']}
      type="button"
      className={styles.button}
    >
      {children}
    </button>
  );
}
