import React, { useEffect, useState } from 'react';
import { FacebookIcon } from '../../svg/FacebookIcon';
import { facebookAuth, loadFacebookSdk } from '../../provider/facebook';
import { RoundedButton } from '../RoundedButton/RoundedButton';

type Props = {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: () => void;
  ga: UniversalAnalytics.ga;
  dataLayer: unknown[];
};

export function ButtonFacebook({
  ga,
  dataLayer,
  onLoginSuccess,
  onError,
  onSignupSuccess,
}: Props) {
  const [facebookSdk, setFacebookSdk] = useState<fb.FacebookStatic | null>(
    null
  );

  useEffect(() => {
    loadFacebookSdk({
      onReady: setFacebookSdk,
    });
  }, []);

  return (
    <RoundedButton
      disabled={facebookSdk === null}
      onClick={() => {
        if (facebookSdk === null) {
          return;
        }
        facebookAuth({
          dataLayer,
          fb: facebookSdk,
          ga,
          onSignupSuccess,
          onLoginSuccess,
          onError,
        });
      }}
      aria-label="Sign up with Facebook"
    >
      <FacebookIcon />
    </RoundedButton>
  );
}
