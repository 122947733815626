import React from 'react';

export function AppleIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.0696 4.91459C14.6969 4.12999 15.1226 3.07653 15.0102 2C14.0921 2.04566 12.9715 2.6058 12.3228 3.391C11.7403 4.06341 11.2247 5.16099 11.3591 6.19239C12.3899 6.2818 13.4197 5.67716 14.0696 4.91459ZM14.9986 6.20714C13.5017 6.11798 12.2289 7.05673 11.514 7.05673C10.7988 7.05673 9.70411 6.25208 8.52006 6.27377C6.97902 6.2964 5.5491 7.16772 4.76708 8.55353C3.1586 11.3258 4.3426 15.4382 5.90677 17.696C6.66636 18.8131 7.5818 20.043 8.78802 19.9988C9.92767 19.9541 10.3744 19.2609 11.7597 19.2609C13.1441 19.2609 13.5465 19.9988 14.7528 19.9765C16.0039 19.9541 16.7861 18.8589 17.5457 17.7407C18.4171 16.4674 18.7738 15.2378 18.7963 15.1703C18.7738 15.1479 16.3838 14.2311 16.3616 11.4819C16.3391 9.17983 18.2381 8.08487 18.3275 8.01697C17.255 6.43092 15.5795 6.25208 14.9986 6.20714Z"
        fill="black"
      />
    </svg>
  );
}
