import React, { ReactNode } from 'react';

import styles from './IconsHorizontal.module.css';

type Props = {
  buttonApple: ReactNode;
  buttonGoogle: ReactNode;
  buttonFacebook: ReactNode;
};

export function IconsHorizontal({
  buttonApple,
  buttonGoogle,
  buttonFacebook,
}: Props) {
  return (
    <ul className={styles['social-buttons']}>
      <li className={styles.social}>{buttonGoogle}</li>
      <li className={styles.social}>{buttonApple}</li>
      <li className={styles.social}>{buttonFacebook}</li>
    </ul>
  );
}
