import { string as zString } from 'zod';
import { postFacebookLogin } from '@hungryroot/rest/api/facebook_login/post';
import { StatusCode } from '@hungryroot/rest';
import { logSso } from './o11y';
import { loadScript } from './provider';

const FACEBOOK_APP_ID = zString().parse(process.env.PUBLIC_FACEBOOK_APP_ID);
const SCRIPT_SRC = 'https://connect.facebook.net/en_US/sdk.js';

type Params = {
  onReady: (sdk: fb.FacebookStatic) => void;
};

export async function loadFacebookSdk({ onReady }: Params) {
  if (window.FB !== undefined) {
    onReady(window.FB);
    return;
  }

  window.fbAsyncInit = function () {
    FB.init({
      appId: FACEBOOK_APP_ID,
      cookie: true,
      status: true,
      autoLogAppEvents: true,
      xfbml: true,
      version: 'v6.0',
    });

    onReady(FB);
  };

  loadScript({ src: SCRIPT_SRC, globalProperty: 'FB' });
}

type AuthParams = {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: (data: unknown) => void;
  fb: Pick<fb.FacebookStatic, 'login'>;
  dataLayer: unknown[];
  ga: UniversalAnalytics.ga;
};

export function facebookAuth({
  onError,
  onSignupSuccess,
  onLoginSuccess,
  dataLayer,
  ga,
  fb,
}: AuthParams) {
  logSso({
    provider: 'facebook',
    lifecycle: 'initiated',
  });
  fb.login(
    (resp) => {
      const accessToken = resp.authResponse?.accessToken;
      const userID = resp.authResponse?.userID;
      const errors: string[] = [];
      if (resp.status !== 'connected') {
        logSso({
          provider: 'facebook',
          lifecycle: 'error',
          error: {
            status: resp.status,
          },
        });
        errors.push('Bad Facebook user ID');
      }

      if (accessToken === undefined) {
        logSso({
          provider: 'facebook',
          lifecycle: 'error',
          error: {
            status: 'undefined_access_token',
          },
        });

        errors.push('Bad Facebook token ');
      }

      if (errors.length > 0) {
        onError(errors);
        return;
      }

      postFacebookLogin({
        userId: userID,
        token: accessToken as string,
      }).then((response) => {
        if (response.status !== StatusCode.SuccessOK) {
          ga('send', 'event', 'login', 'failure');
          logSso({
            provider: 'facebook',
            lifecycle: 'error',
            error: {
              message: 'api_facebook_login_failed',
              statusCode: response.status,
            },
          });
          onError('Bad server response');
          return;
        }

        const {
          data: { customer, created },
        } = response;
        const eventType = created === true ? 'signup' : 'login';
        ga('send', 'event', eventType, 'success');
        dataLayer.push({
          event: `hr.${eventType}.success`,
          [eventType]: {
            method: 'facebook',
          },
          customer,
        });

        logSso({
          provider: 'facebook',
          lifecycle: 'success',
          eventType,
        });

        if (created === true) {
          onSignupSuccess();
          return;
        }

        onLoginSuccess();
      });
    },
    { scope: 'public_profile,email', return_scopes: true }
  );
}
