import React, { useEffect, useRef, useState } from 'react';
import { GoogleIcon } from '../../svg/GoogleIcon';
import {
  googleAuth,
  googleInitialize,
  createGoogleAuthButton,
  Google,
  loadGoogleSdk,
} from '../../provider/google';
import { RoundedButton } from '../RoundedButton/RoundedButton';

import styles from './ButtonGoogle.module.css';

type Props = {
  onLoginSuccess: () => void;
  onSignupSuccess: () => void;
  onError: () => void;
  ga: UniversalAnalytics.ga;
  dataLayer: unknown[];
};

export function ButtonGoogle({
  ga,
  dataLayer,
  onLoginSuccess,
  onError,
  onSignupSuccess,
}: Props) {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const [googleSdk, setGoogleSdk] = useState<Google | null>(null);
  const [googleAuthFn, setGoogleAuthFn] = useState<(() => void) | null>(null);

  useEffect(() => {
    loadGoogleSdk({
      onReady: setGoogleSdk,
    });
  }, []);

  useEffect(() => {
    if (googleSdk === null) {
      return;
    }
    googleInitialize(googleSdk, {
      onAuthRequest(credential) {
        googleAuth({
          onSignupSuccess,
          onLoginSuccess,
          onError,
          dataLayer,
          ga,
          credential,
        });
      },
    });
  }, [googleSdk, onLoginSuccess, onError, onSignupSuccess, ga, dataLayer]);

  useEffect(() => {
    const { current } = containerRef;
    if (current === null || googleSdk === null) {
      return;
    }

    const authFn = createGoogleAuthButton(googleSdk, current);
    setGoogleAuthFn(() => authFn);
  }, [googleSdk]);

  return (
    <RoundedButton
      disabled={googleAuthFn === null}
      onClick={() => {
        if (googleAuthFn === null) {
          return;
        }
        googleAuthFn();
      }}
      aria-label="Sign up with Google"
    >
      <GoogleIcon />
      <div
        className={styles['native-button-container']}
        ref={containerRef}
      ></div>
    </RoundedButton>
  );
}
